import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import OpereList from './OpereList'
import OperaCreate from './OperaCreate'
import OperaEdit from './OperaEdit'

export default function Opere() {
  return (
    <Routes>
      <Route index element={<OpereList />} />
      <Route path="new" element={<OperaCreate />} />
      <Route path=":id" element={<OperaEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
