import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { BasePersona, PaginatedDJResponse, Persona } from '../types'
import { makeFormDataEncoder, QueryOnlyOptions } from './utils'

export function usePersone(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Persona>>(
    ['persone', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/persone/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function usePersona(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<Persona>(
    ['persona', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/persone/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeletePersona() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/persone/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['persone'])
        client.invalidateQueries(['persona'])
      },
    }
  )
}

const makePersonaFormData = makeFormDataEncoder({
  fileFields: ['immagine'],
  excludeFields: [],
})

export function useCreatePersona() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (persona: BasePersona) =>
        axios
          .post(`/api/admin/persone/`, makePersonaFormData(persona), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Persona)
    ),
    {
      onSuccess(savedPersona) {
        client.invalidateQueries(['persone'])
        client.setQueryData(['persona', savedPersona.id], savedPersona)
      },
    }
  )
}

export function useUpdatePersona() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (persona: Persona) =>
        axios
          .put(
            `/api/admin/persone/${persona.id}/`,
            makePersonaFormData(persona),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Persona)
    ),
    {
      onSuccess(savedPersona) {
        client.invalidateQueries(['persone'])
        client.setQueryData(['persona', savedPersona.id], savedPersona)
      },
    }
  )
}
