import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom'
import Auth from './Auth'
import Layout from './components/Layout'
import NotFound from './components/NotFound'
import DataProvider from './DataProvider'
import ErrorBoundary from './ErrorBoundary'
import { RequireAuth, RequireGuest } from './routing'
import RecoverPassword from './pages/RecoverPassword'
import ResetPassword from './pages/ResetPassword'
import Login from './pages/Login'
import { ToastContainer } from 'react-toastify'
import Users from './pages/Users'
import ActivateAccount from './pages/ActivateAccount'
import { User } from './types'
import Profile from './pages/Profile'
import GruppiRicerca from './pages/GruppiRicerca'
import Persone from './pages/Persone'
import CategorieOpere from './pages/CategorieOpere'
import Opere from './pages/Opere'
import Autrici from './pages/Autrici'
import Passi from './pages/Passi'
import Classi from './pages/Classi'
import Temi from './pages/Temi'
import Appuntamenti from './pages/Appuntamenti'
import Testi from './pages/Testi'
import Nomi from './pages/Nomi'
import OpereNomi from './pages/OpereNomi'
import Bibliografie from './pages/Bibliografie'
import Videosaggi from './pages/Videosaggi'

function checkUserIsStaff(user: User) {
  if (!user.is_staff) {
    return '/'
  }
}

export default function App() {
  return (
    <ErrorBoundary>
      <DataProvider>
        <Auth>
          <Router>
            <Routes>
              {/* GUEST */}
              <Route
                path="/"
                element={
                  <RequireGuest>
                    <Outlet />
                  </RequireGuest>
                }
              >
                <Route path="login" element={<Login />} />
                <Route path="password-recover" element={<RecoverPassword />} />
                <Route
                  path="password-reset/:token"
                  element={<ResetPassword />}
                />
                <Route
                  path="activate-account/:token"
                  element={<ActivateAccount />}
                />
              </Route>
              {/* AUTH */}
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Layout />
                  </RequireAuth>
                }
              >
                <Route index element={<Navigate to="/profile" />} />
                <Route path="gruppi-ricerca/*" element={<GruppiRicerca />} />
                <Route path="persone/*" element={<Persone />} />
                <Route path="categorie-opere/*" element={<CategorieOpere />} />
                <Route path="autrici/*" element={<Autrici />} />
                <Route path="opere/*" element={<Opere />} />
                <Route path="passi/*" element={<Passi />} />
                <Route path="temi/*" element={<Temi />} />
                <Route path="classi/*" element={<Classi />} />
                <Route path="appuntamenti/*" element={<Appuntamenti />} />
                <Route path="testi/*" element={<Testi />} />
                <Route path="nomi/*" element={<Nomi />} />
                <Route path="frasi-nomi/*" element={<OpereNomi />} />
                <Route path="bibliografie/*" element={<Bibliografie />} />
                <Route path="profile/*" element={<Profile />} />
                <Route path="videosaggi/*" element={<Videosaggi />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route
                path="/"
                element={
                  <RequireAuth redirectTest={checkUserIsStaff}>
                    <Layout />
                  </RequireAuth>
                }
              >
                <Route path="users/*" element={<Users />} />
              </Route>
              {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
          </Router>
        </Auth>
      </DataProvider>
      <ToastContainer autoClose={3000} draggable={false} />
    </ErrorBoundary>
  )
}
