import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link } from 'react-router-dom'
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import Header from '../../components/Header'
import Page from '../../components/Page'
import Paginator from '../../components/Paginator'
import Sorter, { SortControl } from '../../components/Sorter'
import StickyTable from '../../components/StickyTable'
import { useQsFilters } from '../../hooks/filters'
import { Classe } from '../../types'
import {
  useClassi,
  useClassiAutocomplete,
  useDeleteClasse,
  useUnificaClassi,
} from '../../hooks/classi'
import { useTemiNoPaginate } from '../../hooks/temi'
import { MultiSelectField, ReactSelectField } from '../../components/form'
import { Field, Formik } from 'formik'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  ordering: params.get('ordering') ?? '',
  search: params.get('search') ?? '',
  tema: params.get('tema') ?? '',
  has_descrizione: params.get('has_descrizione') ?? '',
})

export default function ClassiList() {
  const { filters, uiFilters, setFilters, setFiltersDebounced } =
    useQsFilters(initFilters)

  const { data: temi } = useTemiNoPaginate()

  const { data } = useClassi(filters)
  const deleteClasse = useDeleteClasse()
  const [modalRm, modalRmActions] = useModalTrigger<Classe>()
  const [modalUnifica, modalUnificaActions] = useModalTrigger<any>()

  const { data: classi } = useClassiAutocomplete()

  const unificaClassi = useUnificaClassi()

  return (
    <Page padded>
      <Header>
        <div className="d-flex align-items-end">
          <h2 className="m-0">Classi</h2>
          <div className="counter ms-2 mb-1">
            <small>
              {data!.count}
              {' / '}
              {data!.total_count}
            </small>
          </div>
        </div>
        <div className="d-flex">
          <Button
            color="primary"
            onClick={() => modalUnificaActions.open(true)}
            className="me-2"
            outline
          >
            <i className="bi bi-link-45deg me-1"></i> Unifica
          </Button>
          <Link className="btn btn-primary" to="/classi/new">
            <i className="bi bi-plus-circle me-2"></i> Aggiungi
          </Link>
        </div>
      </Header>
      <div className="d-flex mt-3 mb-3 align-items-center">
        <input
          placeholder="Cerca"
          className="form-control w-auto"
          type="text"
          value={uiFilters.search}
          onChange={(e) =>
            setFiltersDebounced({ search: e.target.value, page: 1 })
          }
        />
        {temi && (
          <select
            className="form-select w-auto ms-3"
            value={uiFilters.tema}
            onChange={(e) =>
              setFilters({
                tema: e.target.value,
                page: 1,
              })
            }
          >
            <option value="">Tutti i temi</option>
            {temi.map((tema) => (
              <option key={tema.id} value={tema.id}>
                {tema.nome}
              </option>
            ))}
          </select>
        )}
        <select
          className="form-select w-auto ms-3"
          value={uiFilters.has_descrizione}
          onChange={(e) =>
            setFilters({
              has_descrizione: e.target.value,
              page: 1,
            })
          }
        >
          <option value="">Tutte</option>
          <option value="true">Con descrizione</option>
          <option value="false">Senza descrizione</option>
        </select>
      </div>
      <StickyTable className="flex-1">
        <Sorter
          value={uiFilters.ordering}
          onSortChange={(ordering) =>
            setFilters({
              ordering,
              page: 1,
            })
          }
        >
          <thead>
            <tr>
              <th>#</th>
              <th>
                <div className="d-flex align-items-center">
                  Nome
                  <SortControl field={'nome'} />
                </div>
              </th>
              <th>Descrizione</th>
              <th>
                <div className="d-flex align-items-center">Tema</div>
              </th>
              <th>
                <div className="d-flex align-items-center">Numero passi</div>
              </th>
              <th></th>
            </tr>
          </thead>
        </Sorter>
        <tbody>
          {data!.results.map((classe) => (
            <tr key={classe.id}>
              <td>{classe.id}</td>
              <td>
                <Link to={`/classi/${classe.id}`}>{classe.nome}</Link>
              </td>
              <td>
                {classe.descrizione ? (
                  <Badge color="primary">Si</Badge>
                ) : (
                  <Badge color="danger">No</Badge>
                )}
              </td>
              <td>
                <Link to={`/temi/${classe.tema_data?.id}`}>
                  {classe.tema_data?.nome}
                </Link>
              </td>
              <td>
                <Link to={`/passi?classe=${classe.id}`}>
                  {classe.numero_passi}
                </Link>
              </td>
              <td>
                <i
                  onClick={() => modalRmActions.open(classe)}
                  className="bi cursor-pointer text-danger bi-trash3-fill"
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare la classe {modalRm.value.nome} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteClasse.isLoading}
            onClick={async () => {
              await deleteClasse.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
      <div className="position-relative">
        <Paginator
          count={data!.count}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
      <Modal
        centered
        isOpen={modalUnifica.isOpen}
        toggle={modalUnificaActions.toggle}
      >
        <ModalHeader toggle={modalUnificaActions.toggle}>Unifica</ModalHeader>
        <Formik
          initialValues={{
            classe_to_keep: null,
            classi_to_delete: [],
          }}
          onSubmit={async (values) => {
            console.log(values)
            if (!values.classe_to_keep || values.classi_to_delete.length === 0)
              return
            await unificaClassi
              .mutateAsync({
                classe_to_keep: values.classe_to_keep,
                classi_to_delete: values.classi_to_delete,
              })
              .then(() => {
                modalUnificaActions.toggle()
              })
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <div>
                  <Field
                    component={ReactSelectField}
                    name="classe_to_keep"
                    label="Classe da mantenere"
                    options={classi}
                  />
                </div>
                <div>
                  <Field
                    component={MultiSelectField}
                    name="classi_to_delete"
                    label="Classi da eliminare"
                    options={classi?.filter(
                      (c) => c.value !== values.classe_to_keep
                    )}
                    isMulti
                  />
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-between">
                <button
                  onClick={modalUnificaActions.toggle}
                  className="btn btn-outline-primary"
                >
                  Annulla
                </button>
                <button className="btn btn-success" type="submit">
                  <i className="bi bi-save-fill me-1"></i>
                  Salva
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    </Page>
  )
}
