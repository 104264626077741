import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { BaseBibliografia, Bibliografia, Testo } from '../types'
import { CheckBoxField, InputField, RichTextField, transformErrorForForm } from './form'

const initialValues: BaseBibliografia = {
  descrizione: '',
  descrizione_plain: '',
  pagina_pubblicazione: false,
  cognome: '',
}

const TestoSchema = Yup.object().shape({
  descrizione: Yup.string().label('Descrizione'),
})

interface Props {
  bibliografia?: Bibliografia
  save: (bibliografia: Bibliografia) => Promise<unknown>
}

export default function BibliografiaForm({ save, bibliografia }: Props) {
  return (
    <Formik
      validationSchema={TestoSchema}
      onSubmit={(bibliografia, { setErrors }) =>
        save({
          ...bibliografia,
          //remove all tags from the description and save it as plain text
          descrizione_plain: bibliografia.descrizione.replace(/<[^>]*>?/gm, ''),
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(bibliografia ?? initialValues) as Bibliografia}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12">
              <Field
                required
                label="Descrizione"
                name="descrizione"
                component={RichTextField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Presente in pagina pubblicazione?"
                name="pagina_pubblicazione"
                component={CheckBoxField}
              />
            </div>
            <div className="col-md-12">
              <Field
                required
                label="Cognome"
                name="cognome"
                component={InputField}
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
