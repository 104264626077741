import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { BaseNome, Nome } from '../types'
import { InputField, transformErrorForForm } from './form'

const initialValues: BaseNome = {
    nome: '',
    tipologia: 'PER',
}

const NomeSchema = Yup.object().shape({
    nome: Yup.string().label('Nome'),
})

interface Props {
    nome?: Nome
    save: (nome: Nome) => Promise<unknown>
}

export default function NomeForm({ save, nome }: Props) {
    return (
        <Formik
            validationSchema={NomeSchema}
            onSubmit={(nome, { setErrors }) =>
                save({
                    ...nome,
                }).catch((err) => {
                    setErrors(transformErrorForForm(err))
                })
            }
            validateOnMount
            initialValues={(nome ?? initialValues) as Nome}
        >
            {({ handleSubmit, isSubmitting, isValid }) => (
                <form onSubmit={handleSubmit} className="hm-form">
                    <div className="row">
                        <div className="col-md-12">
                            <Field required label="Nome" name="nome" component={InputField} />
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                            <button
                                type="submit"
                                disabled={isSubmitting || !isValid}
                                className="btn btn-success"
                            >
                                <i className="text-white me-1 bi bi-save-fill"></i> Salva
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}
