import { Link, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Page from '../../components/Page'
import PassoForm from '../../components/PassoForm'
import { useCreatePasso } from '../../hooks/passi'

export default function PassoCreate() {
  const createPasso = useCreatePasso()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/passi">Passi</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <PassoForm
          save={async (passo) => {
            const savedPasso = await createPasso.mutateAsync(passo)
            navigate(`/passi/${savedPasso.id}`)
          }}
        />
      </div>
    </Page>
  )
}
