import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { OperaNomi, PaginatedDJResponse } from '../types'
import { QueryOnlyOptions } from './utils'

export function useOpereNomi(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<OperaNomi>>(
    ['opere-nomi', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/opera-nomi/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}


export function useOperaNome(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<OperaNomi>(
    ['opera-nomi', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/opera-nomi/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteOperaNomi() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/opera-nomi/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['opere-nomi'])
        client.invalidateQueries(['opera-nomi'])
      },
    }
  )
}