import { Link, useNavigate } from 'react-router-dom'
import CategoriaOperaForm from '../../components/CategoriaOperaForm'
import Header from '../../components/Header'
import Page from '../../components/Page'
import { useCreateCategoriaOpera } from '../../hooks/categorie-opere'

export default function CategoriaOperaCreate() {
  const createCategoriaOpera = useCreateCategoriaOpera()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/categorie-opere">Categorie opere</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <CategoriaOperaForm
          save={async (cat) => {
            const savedCat = await createCategoriaOpera.mutateAsync(cat)
            navigate(`/categorie-opere/${savedCat.id}`)
          }}
        />
      </div>
    </Page>
  )
}
