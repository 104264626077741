import { Link, useNavigate, useParams } from 'react-router-dom'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { GruppoRicerca, Nome } from '../../types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Page from '../../components/Page'
import Header from '../../components/Header'
import {
  useDeleteGruppoRicerca,
  useGruppoRicerca,
  useUpdateGruppoRicerca,
} from '../../hooks/gruppi-ricerca'
import GruppoForm from '../../components/GruppoForm'
import { useDeleteNome, useNome, useUpdateNome } from '../../hooks/nomi'
import NomeForm from '../../components/NomeForm'

export default function NomeEdit() {
  const { id } = useParams()
  const { data: nome } = useNome(+id!)
  const deleteNome = useDeleteNome()
  const updateNome = useUpdateNome()
  const [modalRm, modalRmActions] = useModalTrigger<Nome>()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/nomi">Nomi</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {nome!.nome}
            </li>
          </ol>
        </nav>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-danger"
            onClick={() => modalRmActions.open(nome)}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </div>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <NomeForm
          nome={nome}
          save={(gruppo) =>
            updateNome.mutateAsync(gruppo).then(() =>
              toast.success('Nome aggiornato', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              })
            )
          }
        />
      </div>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare il nome {modalRm.value.nome} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn btn-danger"
            disabled={deleteNome.isLoading}
            onClick={async () => {
              await deleteNome.mutateAsync(modalRm.value!.id)
              navigate('/nomi')
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
    </Page>
  )
}
