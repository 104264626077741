import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { BaseVideosaggio, Videosaggio } from '../types'
import {
  InputField,
  TextareaField,
  transformErrorForForm,
} from './form'

const initialValues: BaseVideosaggio = {
  titolo: '',
  descrizione: '',
  video: '',
}

const VideosaggioSchema = Yup.object().shape({
  titolo: Yup.string().label('Titolo').required(),
  descrizione: Yup.string().label('Descrizione').required(),
  video: Yup.string().label('Video').required(),
})

interface Props {
  videosaggio?: Videosaggio
  save: (videosaggio: Videosaggio) => Promise<unknown>
}

export default function VideosaggioForm({ save, videosaggio }: Props) {
  return (
    <Formik
      validationSchema={VideosaggioSchema}
      onSubmit={(videosaggio, { setErrors }) =>
        save({
          ...videosaggio,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(videosaggio ?? initialValues) as Videosaggio}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12">
              <Field
                required
                label="Titolo"
                name="titolo"
                component={InputField}
              />
            </div>
            <div className="col-md-12">
              <Field
                required
                label="Descrizione"
                name="descrizione"
                component={TextareaField}
              />
            </div>
            <div className="col-md-12">
              <Field
                required
                label="Video"
                name="video"
                component={TextareaField}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end mt-2">
            <button
              type="submit"
              disabled={isSubmitting || !isValid}
              className="btn btn-success"
            >
              <i className="text-white me-1 bi bi-save-fill"></i> Salva
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}
