import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { useAutrici } from '../hooks/autrici'
import { BasePasso, Passo } from '../types'
import {
  CheckBoxField,
  InputField,
  MultiSelectField,
  ReactSelectField,
  RichTextField,
  TextareaField,
  transformErrorForForm,
} from './form'
import { useClassiNoPaginate } from '../hooks/classi'
import ModalAssociazioneClasse from './ModalAssociazioneClasse'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useOpere, useOpereAutocomplete } from '../hooks/opere'
import { useBibliografieAutocomplete } from '../hooks/bibliografie'
import { useAdminedUsers } from '../hooks/users'
import { useMemo } from 'react'

const initialValues: BasePasso = {
  testo: '',
  selezionato_da: '',
  indicazione_pagina: '',
  opera: null,
  note: '',
  autore_nota: '',
  bibliografie: [],
  bozza: false,
}

const PassoSchema = Yup.object().shape({
  opera: Yup.string().label('Opera').required(),
  testo: Yup.string().label('Testo').required(),
})

interface Props {
  passo?: Passo
  save: (passo: Passo) => Promise<unknown>
}

export default function PassoForm({ save, passo }: Props) {
  const autriciParams = {
    page: 1,
    page_size: 1000,
  }
  const { data: opere } = useOpereAutocomplete(autriciParams)

  const { data: bibliografie } = useBibliografieAutocomplete()

  const { data: classi } = useClassiNoPaginate()

  const classiOptions = classi?.map((c) => ({
    label: c.nome + ' (' + c.tema_data?.nome + ')',
    value: c.id,
  }))

  const [modalClassi, modalClassiActions] = useModalTrigger()

  const { data: utenti } = useAdminedUsers()

  const optionsACuraDi = useMemo(() => {
    const utentiFilteredInmagik = utenti?.results?.filter(
      (u) => !u.email.includes('inmagik')
    )
    return utentiFilteredInmagik?.map((u) => ({
      label: 'A cura di ' + u.name,
      value: 'A cura di ' + u.name,
    }))
  }, [utenti])

  return (
    <Formik
      validationSchema={PassoSchema}
      onSubmit={(passo, { setErrors }) =>
        save({
          ...passo,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(passo ?? initialValues) as Passo}
    >
      {({ handleSubmit, isSubmitting, isValid, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12">
              <Field
                required
                label="Opera"
                name="opera"
                placeholder="Seleziona un'opera"
                options={opere}
                component={ReactSelectField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Selezionato da"
                name="selezionato_da"
                component={InputField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label="Indicazione pagina"
                name="indicazione_pagina"
                component={InputField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Testo"
                required
                rows={6}
                name="testo"
                component={RichTextField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Note"
                rows={4}
                name="note"
                component={RichTextField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Autore nota"
                name="autore_nota"
                component={ReactSelectField}
                options={optionsACuraDi}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Bibliografie"
                name="bibliografie"
                component={MultiSelectField}
                placeholder="Seleziona le bibliografie"
                isMulti
                options={bibliografie}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Bozza"
                name="bozza"
                component={CheckBoxField}
                type="checkbox"
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Classi"
                name="classi"
                placeholder="Seleziona le classi"
                options={classiOptions}
                component={MultiSelectField}
                isClearable={false}
              />
              <div className="mt-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    modalClassiActions.toggle()
                  }}
                >
                  <i className="bi bi-plus"></i> Gestisci parole chiave
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </div>
          <ModalAssociazioneClasse
            isOpen={modalClassi.isOpen}
            toggle={modalClassiActions.toggle}
            classi={classi}
            passo={passo}
            onSave={(classi) => {
              // set the value of the field
              // find the classi in the optionsClassi
              const classiValues = classi.map((c) => c.id)
              setFieldValue('classi', classiValues)
              modalClassiActions.toggle()
            }}
          />
        </form>
      )}
    </Formik>
  )
}
