import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import OpereNomiList from './OpereNomiList'

export default function OpereNomi() {
  return (
    <Routes>
      <Route index element={<OpereNomiList />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
