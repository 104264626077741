import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { Autrice, BaseAttrice } from '../types'
import { FileField, InputField, TextareaField, transformErrorForForm } from './form'

const initialValues: BaseAttrice = {
  nome: '',
  cognome: '',
  attivita_sceneggiatrice: '',
  codice: '',
  biografia: '',
  immagine: '',
  immagine_home: '',
}

const AttriceSchema = Yup.object().shape({
  nome: Yup.string().label('Nome').required(),
  cognome: Yup.string().label('Cognome').required(),
})

interface Props {
  autrice?: Autrice
  save: (autrice: Autrice) => Promise<unknown>
}

export default function AutriceForm({ save, autrice }: Props) {
  return (
    <Formik
      validationSchema={AttriceSchema}
      onSubmit={(attrice, { setErrors }) =>
        save({
          ...attrice,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(autrice ?? initialValues) as Autrice}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-6">
              <Field required label="Nome" name="nome" component={InputField} />
            </div>
            <div className="col-md-6">
              <Field
                required
                label="Cognome"
                name="cognome"
                component={InputField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Biografia"
                name="biografia"
                rows={4}
                component={TextareaField}
              />
            </div>
            <div className='col-md-12'>
              <Field
                label="Immagine homepage"
                name="immagine_home"
                component={FileField}
                fileType="image"

              />
            </div>
            <div className="col-md-6">
              <Field
                label="Anno di nascita"
                name="anno_nascita"
                component={InputField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label="Anno di morte"
                name="anno_morte"
                component={InputField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Attività sceneggiatrice"
                name="attivita_sceneggiatrice"
                rows={4}
                component={TextareaField}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end mt-2">
            <button
              type="submit"
              disabled={isSubmitting || !isValid}
              className="btn btn-success"
            >
              <i className="text-white me-1 bi bi-save-fill"></i> Salva
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}
