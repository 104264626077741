import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Appuntamento, BaseAppuntamento, PaginatedDJResponse } from '../types'
import { QueryOnlyOptions } from './utils'

export function useAppuntamenti(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Appuntamento>>(
    ['appuntamenti', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/appuntamenti/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useAppuntamento(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<Appuntamento>(
    ['appuntamento', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/appuntamenti/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteAppuntamento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/appuntamenti/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['appuntamenti'])
        client.invalidateQueries(['appuntamento'])
      },
    }
  )
}

export function useCreateAppuntamento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (appuntamento: BaseAppuntamento) =>
        axios
          .post(`/api/admin/appuntamenti/`,appuntamento, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Appuntamento)
    ),
    {
      onSuccess(savedAppuntamento) {
        client.invalidateQueries(['appuntamenti'])
        client.setQueryData(['appuntamento', savedAppuntamento.id], savedAppuntamento)
      },
    }
  )
}

export function useUpdateAppuntamento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (appuntamento: Appuntamento) =>
        axios
          .put(
            `/api/admin/appuntamenti/${appuntamento.id}/`,
            appuntamento,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Appuntamento)
    ),
    {
      onSuccess(savedAppuntamento) {
        client.invalidateQueries(['appuntamenti'])
        client.setQueryData(['appuntamento', savedAppuntamento.id], savedAppuntamento)
      },
    }
  )
}
