import { Link, useNavigate, useParams } from 'react-router-dom'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Videosaggio } from '../../types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Page from '../../components/Page'
import Header from '../../components/Header'
import { useDeleteVideosaggio, useUpdateVideosaggio, useVideosaggio } from '../../hooks/videosaggi'
import VideosaggioForm from '../../components/VideosaggioForm'

export default function VideosaggioEdit() {
  const { id } = useParams()
  const { data: videosaggio } = useVideosaggio(+id!)
  const deleteVideosaggio = useDeleteVideosaggio()
  const updateVideosaggio = useUpdateVideosaggio()
  const [modalRm, modalRmActions] = useModalTrigger<Videosaggio>()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/videosaggi">Videosaggi</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {videosaggio!.titolo}
            </li>
          </ol>
        </nav>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-danger"
            onClick={() => modalRmActions.open(videosaggio)}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </div>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <VideosaggioForm
          videosaggio={videosaggio}
          save={(video) =>
            updateVideosaggio.mutateAsync(video).then(() =>
              toast.success('Videosaggio salvato con successo!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              })
            )
          }
        />
      </div>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare il video {modalRm.value.titolo}?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn btn-danger"
            disabled={deleteVideosaggio.isLoading}
            onClick={async () => {
              await deleteVideosaggio.mutateAsync(modalRm.value!.id)
              navigate('/testi')
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
    </Page>
  )
}
