import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { BaseOpera, Opera, PaginatedDJResponse, ReactSelectOption } from '../types'
import { makeFormDataEncoder, QueryOnlyOptions } from './utils'

export function useOpere(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Opera>>(
    ['opere', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/opere/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useOpereAutocomplete(params: Record<string, any> = {}) {

  return useQuery<ReactSelectOption[]>(
    ['opere-options', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/opere/autocomplete/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useOpera(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<Opera>(
    ['opera', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/opere/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteOpera() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/opere/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['opere'])
        client.invalidateQueries(['opera'])
      },
    }
  )
}

const makeOperaFormData = makeFormDataEncoder({
  fileFields: ['immagine'],
  excludeFields: [],
})

export function useCreateOpera() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (opera: BaseOpera) =>
        axios
          .post(`/api/admin/opere/`, makeOperaFormData(opera), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Opera)
    ),
    {
      onSuccess(savedOpera) {
        client.invalidateQueries(['opere'])
        client.setQueryData(['opera', savedOpera.id], savedOpera)
      },
    }
  )
}

export function useUpdateOpera() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (opera: Opera) =>
        axios
          .put(
            `/api/admin/opere/${opera.id}/`,
            makeOperaFormData(opera),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Opera)
    ),
    {
      onSuccess(savedOpera) {
        client.invalidateQueries(['opere'])
        client.setQueryData(['opera', savedOpera.id], savedOpera)
      },
    }
  )
}
