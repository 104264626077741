import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import VideosaggiList from './VideosaggiList'
import VideosaggioEdit from './VideosaggioEdit'
import VideosaggioCreate from './VideosaggioCreate'

export default function Videosaggi() {
  return (
    <Routes>
      <Route index element={<VideosaggiList />} />
      <Route path="new" element={<VideosaggioCreate />} />
      <Route path=":id" element={<VideosaggioEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
