import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { BaseTesto, PaginatedDJResponse, Testo } from '../types'
import { QueryOnlyOptions } from './utils'

export function useTesti(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Testo>>(
    ['testi', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/testi/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useTesto(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<Testo>(
    ['testo', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/testi/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteTesto() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/testi/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['testi'])
        client.invalidateQueries(['testo'])
      },
    }
  )
}

export function useCreateTesto() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (testo: BaseTesto) =>
        axios
          .post(`/api/admin/testi/`,testo, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Testo)
    ),
    {
      onSuccess(savedTesto) {
        client.invalidateQueries(['testi'])
        client.setQueryData(['testo', savedTesto.id], savedTesto)
      },
    }
  )
}

export function useUpdateTesto() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (testo: Testo) =>
        axios
          .put(
            `/api/admin/testi/${testo.id}/`,
            testo,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Testo)
    ),
    {
      onSuccess(savedTesto) {
        client.invalidateQueries(['testi'])
        client.setQueryData(['testo', savedTesto.id], savedTesto)
      },
    }
  )
}
