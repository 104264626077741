import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Header from '../../components/Header'
import Page from '../../components/Page'
import Sorter, { SortControl } from '../../components/Sorter'
import StickyTable from '../../components/StickyTable'
import { useQsFilters } from '../../hooks/filters'
import { OperaNomi } from '../../types'
import { useDeleteOperaNomi, useOpereNomi } from '../../hooks/opere-nomi'
import { useOpereAutocomplete } from '../../hooks/opere'
import ReactSelect from 'react-select'
import { useNomiAutocomplete } from '../../hooks/nomi'
import Paginator from '../../components/Paginator'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  ordering: params.get('ordering') ?? '',
  search: params.get('search') ?? '',
  opera: params.get('opera') ?? '',
  nome: params.get('nome') ?? '',
})

export default function OpereNomiList() {
  const { filters, uiFilters, setFilters, setFiltersDebounced } =
    useQsFilters(initFilters)
  const { data, refetch } = useOpereNomi(filters)
  const deleteOperaNome = useDeleteOperaNomi()
  const [modalRm, modalRmActions] = useModalTrigger<OperaNomi>()

  const opereParams = {
    page: 1,
    page_size: 1000,
  }

  const { data: opere } = useOpereAutocomplete(opereParams)
  const { data: nomi } = useNomiAutocomplete(opereParams)

  return (
    <Page padded>
      <Header>
        <div className="d-flex align-items-end">
          <h2 className="m-0">Frasi Nomi</h2>
          <div className="counter ms-2 mb-1">
            <small>
              {data!.count}
              {' / '}
              {data!.total_count}
            </small>
          </div>
        </div>
      </Header>
      <div className="d-flex justify-content-between mt-3 mb-3 align-items-center">
        <input
          placeholder="Cerca"
          className="form-control w-auto"
          type="text"
          value={uiFilters.search}
          onChange={(e) =>
            setFiltersDebounced({ search: e.target.value, page: 1 })
          }
        />
        {opere && (
          <div
            style={{
              zIndex: 1000,
              width: 500,
            }}
          >
            <ReactSelect
              className="ms-3"
              placeholder="Seleziona un'opera"
              value={opere.find((o) => o.value === Number(filters.opera))}
              isClearable
              onChange={(opera) => {
                if (!opera) {
                  setFilters({
                    opera: '',
                    page: 1,
                  })
                  return
                } else {
                  setFilters({
                    opera: String(opera?.value) ?? '',
                    page: 1,
                  })
                }
              }}
              options={opere}
            />
          </div>
        )}
        {nomi && (
          <div
            style={{
              zIndex: 1000,
              width: 500,
            }}
          >
            <ReactSelect
              className="ms-3"
              placeholder="Seleziona un nome"
              value={nomi.find((n) => n.value === Number(filters.nome))}
              isClearable
              onChange={(nome) => {
                if (!nome) {
                  setFilters({
                    nome: '',
                    page: 1,
                  })
                  return
                } else {
                  setFilters({
                    nome: String(nome?.value) ?? '',
                    page: 1,
                  })
                }
              }}
              options={nomi}
            />
          </div>
        )}
      </div>
      <StickyTable className="flex-1">
        <Sorter
          value={uiFilters.ordering}
          onSortChange={(ordering) =>
            setFilters({
              ordering,
              page: 1,
            })
          }
        >
          <thead>
            <tr>
              <th>#</th>
              <th>
                <div className="d-flex align-items-center">
                  Nome
                  <SortControl field={'nome__nome'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Opera
                  <SortControl field={'opera__titolo'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Inizio
                  <SortControl field={'inizio'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Fine
                  <SortControl field={'fine'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Frase
                  <SortControl field={'frase'} />
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
        </Sorter>
        <tbody>
          {data!.results.map((nome) => (
            <tr key={nome.id}>
              <td>{nome.id}</td>
              <td>{nome?.nome_data?.nome}</td>
              <td>
                <Link to={`/opere/${nome?.opera_data?.id}`}>
                  {nome?.opera_data?.titolo}
                </Link>
              </td>
              <td>{nome.inizio}</td>
              <td>{nome.fine}</td>
              <td>{nome.frase}</td>
              <td>
                <i
                  onClick={() => modalRmActions.open(nome)}
                  className="bi cursor-pointer text-danger bi-trash3-fill"
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare l'opera nome{' '}
              {modalRm.value.nome_data?.nome} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteOperaNome.isLoading}
            onClick={async () => {
              await deleteOperaNome.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
      <div className="position-relative">
        <Paginator
          count={data!.count}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </Page>
  )
}
