import { Link, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Page from '../../components/Page'
import { useCreateClasse } from '../../hooks/classi'
import ClasseForm from '../../components/ClasseForm'

export default function ClasseCreate() {
  const createClasse = useCreateClasse()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/classi">Classe</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <ClasseForm
          save={async (classe) => {
            const savedClasse = await createClasse.mutateAsync(classe)
            navigate(`/classi/${savedClasse.id}`)
          }}
        />
      </div>
    </Page>
  )
}
