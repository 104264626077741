import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import NomiList from './NomiList'
import NomeEdit from './NomeEdit'

export default function Nomi() {
  return (
    <Routes>
      <Route index element={<NomiList />} />
      <Route path=":id" element={<NomeEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
