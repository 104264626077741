import { Link, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Page from '../../components/Page'
import { useCreateBibliografia } from '../../hooks/bibliografie'
import BibliografiaForm from '../../components/BibliografiaForm'

export default function BibliografieCreate() {
  const createBiografia = useCreateBibliografia()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/bibliografie">Bibliografie</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <BibliografiaForm
          save={async (biblio) => {
            const savedBiografia = await createBiografia.mutateAsync(biblio)
            navigate(`/bibliografie/${savedBiografia.id}`)
          }}
        />
      </div>
    </Page>
  )
}
