import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import AppuntamentiList from './AppuntamentiList'
import AppuntamentoEdit from './AppuntamentoEdit'
import AppuntamentoCreate from './AppuntamentoCreate'

export default function Appuntamenti() {
  return (
    <Routes>
      <Route index element={<AppuntamentiList />} />
      <Route path=":id" element={<AppuntamentoEdit />} />
      <Route path="new" element={<AppuntamentoCreate />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
