import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { useAutrici } from '../hooks/autrici'
import { useCategorieOpere } from '../hooks/categorie-opere'
import { BaseCategoriaOpera, BaseOpera, Opera } from '../types'
import {
    FileField,
  InputField,
  MultiSelectField,
  ReactSelectField,
  SelectField,
  TextareaField,
  transformErrorForForm,
} from './form'
import { useBibliografieAutocomplete } from '../hooks/bibliografie'

const initialValues: BaseOpera = {
  autrice: null,
  quarta_copertina: '',
  coauture: '',
  descrizione: '',
  editore: '',
  immagine: '',
  stato_pubblicazione: '',
  titolo: '',
  note: '',
  categorie: [],
  bibliografie: [],
  anno_pubblicazione: null,
}

const OperaSchema = Yup.object().shape({
  titolo: Yup.string().label('Titolo').required(),
  editore: Yup.string().label('Editore').required(),
  autrice: Yup.string().label('Autrice').required(),
  stato_pubblicazione: Yup.string().label('Stato pubblicazione').required(),
})

interface Props {
  opera?: Opera
  save: (opera: Opera) => Promise<unknown>
}

export default function OperaForm({ save, opera }: Props) {
  const { data: categorie } = useCategorieOpere()
  const { data: autrici } = useAutrici()

  const paramsBibliografie = {
    page: 1,
    page_size: 1000,
  }

  const { data: bibliografie } = useBibliografieAutocomplete(paramsBibliografie)

  const categorieOptions = categorie?.map((c: BaseCategoriaOpera) => ({
    label: c.nome,
    value: c.id,
  }))

  const autriciOptions = autrici?.map((a) => ({
    label: a.nome + ' ' + a.cognome,
    value: a.id,
  }))

  return (
    <Formik
      validationSchema={OperaSchema}
      onSubmit={(opera, { setErrors }) =>
        save({
          ...opera,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(opera ?? initialValues) as Opera}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12">
              <Field
                required
                label="Titolo"
                name="titolo"
                component={InputField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Categorie"
                name="categorie"
                options={categorieOptions}
                isMulti
                component={MultiSelectField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label="Anno pubblicazione"
                name="anno_pubblicazione"
                component={InputField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label="Autrice"
                required
                name="autrice"
                options={autriciOptions}
                component={ReactSelectField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label="Coautore"
                name="coautore"
                component={InputField}
              />
            </div>
            <div className="col-md-6">
              <Field
                required
                label="Editore"
                name="editore"
                component={InputField}
              />
            </div>
            <div className="col-md-6">
              <Field
                required
                label="Stato pubblicazione"
                name="stato_pubblicazione"
                component={SelectField}
              >
                <option value={''}>Seleziona</option>
                <option value={'fuori commercio'}>Fuori commercio</option>
                <option value={'in commercio'}>In commercio</option>
              </Field>
            </div>
            <div className="col-md-6">
              <Field label="Immagine" name="immagine" component={FileField}  fileType="image" />
            </div>
            <div className="col-md-12">
              <Field
                label="Quarta di copertina"
                name="quarta_copertina"
                rows={6}
                component={TextareaField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Descrizione"
                name="descrizione"
                rows={6}
                component={TextareaField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Note"
                rows={4}
                name="note"
                component={TextareaField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Bibliografie"
                name="bibliografie"
                options={bibliografie}
                component={MultiSelectField}
                isMulti
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
