import { Link, useNavigate, useParams } from 'react-router-dom'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Autrice } from '../../types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Page from '../../components/Page'
import Header from '../../components/Header'
import {
  useAutrice,
  useDeleteAutrice,
  useUpdateAutrice,
} from '../../hooks/autrici'
import AutriceForm from '../../components/AutriceForm'

export default function AutriceEdit() {
  const { id } = useParams()
  const { data: autrice } = useAutrice(+id!)
  const deleteAutrice = useDeleteAutrice()
  const updateAutrice = useUpdateAutrice()
  const [modalRm, modalRmActions] = useModalTrigger<Autrice>()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/autrici">Autrici</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {autrice!.nome} {autrice!.cognome}
            </li>
          </ol>
        </nav>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-danger"
            onClick={() => modalRmActions.open(autrice)}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </div>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <AutriceForm
          autrice={autrice}
          save={(autrice) =>
            updateAutrice.mutateAsync(autrice).then(() =>
              toast.success('Autrice salvata con successo!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              })
            )
          }
        />
      </div>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare l'autrice {modalRm.value.nome}{' '}
              {modalRm.value.cognome} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn btn-danger"
            disabled={deleteAutrice.isLoading}
            onClick={async () => {
              await deleteAutrice.mutateAsync(modalRm.value!.id)
              navigate('/autrici')
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
    </Page>
  )
}
