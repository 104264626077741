import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Header from '../../components/Header'
import Page from '../../components/Page'
import Sorter, { SortControl } from '../../components/Sorter'
import StickyTable from '../../components/StickyTable'
import { useQsFilters } from '../../hooks/filters'
import { CategoriaOpera } from '../../types'
import {
  useCategorieOpere,
  useDeleteCategoriaOpera,
  useUnificaCategorieOpera,
} from '../../hooks/categorie-opere'
import { Field, Formik } from 'formik'
import { useMemo } from 'react'
import { MultiSelectField, ReactSelectField } from '../../components/form'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  ordering: params.get('ordering') ?? '',
  search: params.get('search') ?? '',
})

export default function CategorieOpereList() {
  const { filters, uiFilters, setFilters, setFiltersDebounced } =
    useQsFilters(initFilters)
  const { data } = useCategorieOpere(filters)
  const deleteCategoria = useDeleteCategoriaOpera()
  const [modalRm, modalRmActions] = useModalTrigger<CategoriaOpera>()

  const [modalUnifica, modalUnificaActions] = useModalTrigger<boolean>()

  const optionsCategorie = useMemo(
    () =>
      data?.map((c) => ({
        label: c.nome,
        value: c.id,
      })) ?? [],
    [data]
  )

  const unificaCategorie = useUnificaCategorieOpera()

  return (
    <Page padded>
      <Header>
        <div className="d-flex align-items-end">
          <h2 className="m-0">Categorie opere</h2>
        </div>
        <div>
          <Button
            color="primary"
            onClick={() => modalUnificaActions.open(true)}
            className="me-2"
            outline
          >
            <i className="bi bi-link-45deg me-1"></i> Unifica
            
          </Button>
          <Link className="btn btn-primary" to="/categorie-opere/new">
            <i className="bi bi-plus-circle me-2"></i> Aggiungi
          </Link>
        </div>
      </Header>
      <div className="d-flex justify-content-between mt-3 mb-3 align-items-center">
        <input
          placeholder="Cerca"
          className="form-control w-auto"
          type="text"
          value={uiFilters.search}
          onChange={(e) =>
            setFiltersDebounced({ search: e.target.value, page: 1 })
          }
        />
      </div>
      <StickyTable className="flex-1">
        <Sorter
          value={uiFilters.ordering}
          onSortChange={(ordering) =>
            setFilters({
              ordering,
              page: 1,
            })
          }
        >
          <thead>
            <tr>
              <th>#</th>
              <th>
                <div className="d-flex align-items-center">
                  Nome
                  <SortControl field={'nome'} />
                </div>
              </th>

              <th></th>
            </tr>
          </thead>
        </Sorter>
        <tbody>
          {data!.map((categoria) => (
            <tr key={categoria.id}>
              <td>{categoria.id}</td>
              <td>
                <Link to={`/categorie-opere/${categoria.id}`}>
                  {categoria.nome}
                </Link>
              </td>
              <td>
                <i
                  onClick={() => modalRmActions.open(categoria)}
                  className="bi cursor-pointer text-danger bi-trash3-fill"
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare la categoria {modalRm.value.nome} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteCategoria.isLoading}
            onClick={async () => {
              await deleteCategoria.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
      <Modal
        centered
        isOpen={modalUnifica.isOpen}
        toggle={modalUnificaActions.toggle}
      >
        <ModalHeader toggle={modalUnificaActions.toggle}>Unifica</ModalHeader>
        <Formik
          initialValues={{
            categoria_to_keep: null,
            categorie_to_delete: [],
          }}
          onSubmit={async (values) => {
            console.log(values)
            if (
              !values.categoria_to_keep ||
              values.categorie_to_delete.length === 0
            )
              return
            await unificaCategorie.mutateAsync({
              categoria_to_keep: values.categoria_to_keep,
              categorie_to_delete: values.categorie_to_delete,
            }).then(() => {
              modalUnificaActions.toggle()
            }
            )
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <div>
                  <Field
                    component={ReactSelectField}
                    name="categoria_to_keep"
                    label="Categoria da mantenere"
                    options={optionsCategorie}
                  />
                </div>
                <div>
                  <Field
                    component={MultiSelectField}
                    name="categorie_to_delete"
                    label="Categorie da eliminare"
                    options={optionsCategorie.filter(
                      (c) => c.value !== values.categoria_to_keep
                    )}
                    isMulti
                  />
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-between">
                <button
                  onClick={modalUnificaActions.toggle}
                  className="btn btn-outline-primary"
                >
                  Annulla
                </button>
                <button className="btn btn-success" type="submit">
                  <i className="bi bi-save-fill me-1"></i>
                  Salva
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    </Page>
  )
}
