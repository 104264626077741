import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import TemiList from './TemiList'
import TemaCreate from './TemaCreate'
import TemaEdit from './TemaEdit'

export default function Temi() {
  return (
    <Routes>
      <Route index element={<TemiList />} />
      <Route path="new" element={<TemaCreate />} />
      <Route path=":id" element={<TemaEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
