import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Header from '../../components/Header'
import Page from '../../components/Page'
import Sorter, { SortControl } from '../../components/Sorter'
import StickyTable from '../../components/StickyTable'
import { useQsFilters } from '../../hooks/filters'
import { Autrice } from '../../types'
import { useAutrici, useDeleteAutrice } from '../../hooks/autrici'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  ordering: params.get('ordering') ?? '',
  search: params.get('search') ?? '',
})

export default function AutriciList() {
  const { filters, uiFilters, setFilters, setFiltersDebounced } =
    useQsFilters(initFilters)
  const { data } = useAutrici(filters)
  const deleteAutrice = useDeleteAutrice()
  const [modalRm, modalRmActions] = useModalTrigger<Autrice>()

  return (
    <Page padded>
      <Header>
        <div className="d-flex align-items-end">
          <h2 className="m-0">Autrici</h2>
        </div>
        <Link className="btn btn-primary" to="/autrici/new">
          <i className="bi bi-plus-circle me-2"></i> Aggiungi
        </Link>
      </Header>
      <div className="d-flex justify-content-between mt-3 mb-3 align-items-center">
        <input
          placeholder="Cerca"
          className="form-control w-auto"
          type="text"
          value={uiFilters.search}
          onChange={(e) =>
            setFiltersDebounced({ search: e.target.value, page: 1 })
          }
        />
      </div>
      <StickyTable className="flex-1">
        <Sorter
          value={uiFilters.ordering}
          onSortChange={(ordering) =>
            setFilters({
              ordering,
              page: 1,
            })
          }
        >
          <thead>
            <tr>
              <th>
                <div className="d-flex align-items-center">
                  Codice
                  <SortControl field={'codice'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Nome
                  <SortControl field={'nome'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Cognome
                  <SortControl field={'cognome'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Anno nascita
                  <SortControl field={'anno_nascita'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Anno morte
                  <SortControl field={'anno_morte'} />
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
        </Sorter>
        <tbody>
          {data!.map((attrice) => (
            <tr key={attrice.id}>
              <td>{attrice.codice}</td>
              <td>
                <Link to={`/autrici/${attrice.id}`}>{attrice.nome}</Link>
              </td>
              <td>{attrice.cognome}</td>
              <td>{attrice.anno_nascita}</td>
              <td>{attrice.anno_morte}</td>
              <td>
                <i
                  onClick={() => modalRmActions.open(attrice)}
                  className="bi cursor-pointer text-danger bi-trash3-fill"
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare l'autrice {modalRm.value.nome}{' '}
              {modalRm.value.cognome} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteAutrice.isLoading}
            onClick={async () => {
              await deleteAutrice.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
    </Page>
  )
}
