import { Link, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import OperaForm from '../../components/OperaForm'
import Page from '../../components/Page'
import { useCreateOpera } from '../../hooks/opere'

export default function OperaCreate() {
  const createOpera = useCreateOpera()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/opere">Opere</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <OperaForm
          save={async (opera) => {
            const savedOpera = await createOpera.mutateAsync(opera)
            navigate(`/opere/${savedOpera.id}`)
          }}
        />
      </div>
    </Page>
  )
}
