import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  BaseGruppoRicerca,
  GruppoRicerca,
} from '../types'
import { QueryOnlyOptions } from './utils'

export function useGruppiRicerca(params: Record<string, any> = {}) {
  return useQuery<GruppoRicerca[]>(
    ['gruppi-ricerca', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/gruppi-ricerca/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useGruppoRicerca(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<GruppoRicerca>(
    ['gruppo-ricerca', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/gruppi-ricerca/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteGruppoRicerca() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/gruppi-ricerca/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['gruppi-ricerca'])
        client.invalidateQueries(['gruppo-ricerca'])
      },
    }
  )
}

export function useCreateGruppoRicerca() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (gruppo: BaseGruppoRicerca) =>
        axios
          .post(`/api/admin/gruppi-ricerca/`, gruppo, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as GruppoRicerca)
    ),
    {
      onSuccess(savedGruppo) {
        client.invalidateQueries(['gruppi-ricerca'])
        client.setQueryData(['gruppo-ricerca', savedGruppo.id], savedGruppo)
      },
    }
  )
}

export function useUpdateGruppoRicerca() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (gruppo: GruppoRicerca) =>
        axios
          .put(`/api/admin/gruppi-ricerca/${gruppo.id}/`, gruppo, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as GruppoRicerca)
    ),
    {
      onSuccess(savedGruppo) {
        client.invalidateQueries(['gruppi-ricerca'])
        client.setQueryData(['gruppo-ricerca', savedGruppo.id], savedGruppo)
      },
    }
  )
}
