import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { useGruppiRicerca } from '../hooks/gruppi-ricerca'
import { BasePersona, Persona } from '../types'
import {
  convertRichTextFieldToPlainText,
  FileField,
  InputField,
  RichTextField,
  SelectField,
  TextareaField,
  transformErrorForForm,
} from './form'

const initialValues: BasePersona = {
  nome: '',
  ordine: 0,
  gruppo: null,
  descrizione: '',
  immagine: '',
  descrizione_lunga: '',
  descrizione_lunga_semplice: '',
}

const PersonaSchema = Yup.object().shape({
  nome: Yup.string().label('Nome'),
  ordine: Yup.number().label('Ordine'),
})

interface Props {
  persona?: Persona
  save: (user: Persona) => Promise<unknown>
}

export default function PersonaForm({ save, persona }: Props) {
  const { data: gruppi } = useGruppiRicerca()
  return (
    <Formik
      validationSchema={PersonaSchema}
      onSubmit={(persona, { setErrors }) =>
        save({
          ...persona,
          descrizione_lunga_semplice: convertRichTextFieldToPlainText(
            persona.descrizione_lunga || ''
          ),
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(persona ?? initialValues) as Persona}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-6">
              <Field required label="Nome" name="nome" component={InputField} />
            </div>
            <div className="col-md-6">
              <Field
                required
                label={'Gruppo'}
                name={'gruppo'}
                component={SelectField}
              >
                <option value=""></option>
                {gruppi?.map((gruppo) => (
                  <option key={gruppo.id} value={gruppo.id}>
                    {gruppo.nome}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-md-12">
              <Field
                label="Descrizione breve"
                name="descrizione"
                rows={4}
                component={TextareaField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label={`Descrizione lunga`}
                name={`descrizione_lunga`}
                component={RichTextField}
              />
            </div>
            <div className="col-md-6">
              <Field
                label="Immagine"
                component={FileField}
                fileType="image"
                name="immagine"
              />
            </div>
            <div className="col-md-6">
              <Field label="Ordine" name="ordine" component={InputField} />
            </div>
          </div>

          <div className="d-flex justify-content-end mt-2">
            <button
              type="submit"
              disabled={isSubmitting || !isValid}
              className="btn btn-success"
            >
              <i className="text-white me-1 bi bi-save-fill"></i> Salva
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}
