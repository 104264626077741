import { Link, useNavigate } from 'react-router-dom'
import GruppoForm from '../../components/GruppoForm'
import Header from '../../components/Header'
import Page from '../../components/Page'
import { useCreateGruppoRicerca } from '../../hooks/gruppi-ricerca'

export default function GruppoCreate() {
  const createGruppo = useCreateGruppoRicerca()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/gruppi-ricerca">Gruppi ricerca</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <GruppoForm
          save={async (gruppo) => {
            const savedGruppo = await createGruppo.mutateAsync(gruppo)
            navigate(`/gruppi-ricerca/${savedGruppo.id}`)
          }}
        />
      </div>
    </Page>
  )
}
