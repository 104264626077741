import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Autrice, BaseAttrice } from '../types'
import { makeFormDataEncoder, QueryOnlyOptions } from './utils'

export function useAutrici(params: Record<string, any> = {}) {
  return useQuery<Autrice[]>(
    ['autrici', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/autrici/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useAutrice(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<Autrice>(
    ['autrice', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/autrici/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteAutrice() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/autrici/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['autrici'])
        client.invalidateQueries(['autrice'])
      },
    }
  )
}

const makeAutriceFormData = makeFormDataEncoder({
  fileFields: ['immagine_home'],
  excludeFields: [],
})

export function useCreateAutrice() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (autrice: BaseAttrice) =>
        axios
          .post(`/api/admin/autrici/`, makeAutriceFormData(autrice), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Autrice)
    ),
    {
      onSuccess(savedAutrice) {
        client.invalidateQueries(['autrici'])
        client.setQueryData(['autrice', savedAutrice.id], savedAutrice)
      },
    }
  )
}

export function useUpdateAutrice() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (autrice: Autrice) =>
        axios
          .put(
            `/api/admin/autrici/${autrice.id}/`,
            makeAutriceFormData(autrice),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Autrice)
    ),
    {
      onSuccess(savedAutrice) {
        client.invalidateQueries(['autrici'])
        client.setQueryData(['autrice', savedAutrice.id], savedAutrice)
      },
    }
  )
}
