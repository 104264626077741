import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { GruppoRicerca, BaseGruppoRicerca } from '../types'
import { InputField, RichTextField, transformErrorForForm } from './form'

const initialValues: BaseGruppoRicerca = {
  nome: '',
  ordine: 0,
  descrizione_html: '',
}

const GruppoSchema = Yup.object().shape({
  nome: Yup.string().label('Nome'),
  order: Yup.number().label('Ordine'),
})

interface Props {
  gruppo?: GruppoRicerca
  save: (gruppo: GruppoRicerca) => Promise<unknown>
}

export default function GruppoForm({ save, gruppo }: Props) {
  return (
    <Formik
      validationSchema={GruppoSchema}
      onSubmit={(gruppo, { setErrors }) =>
        save({
          ...gruppo,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(gruppo ?? initialValues) as GruppoRicerca}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-6">
              <Field required label="Nome" name="nome" component={InputField} />
            </div>
            <div className="col-md-6">
              <Field required label="Ordine" name="ordine" component={InputField} />
            </div>
            <div className='col-md-12'>
              <Field label="Descrizione" name="descrizione_html" component={RichTextField} />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
