import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Header from '../../components/Header'
import Page from '../../components/Page'
import Paginator from '../../components/Paginator'
import Sorter, { SortControl } from '../../components/Sorter'
import StickyTable from '../../components/StickyTable'
import { useQsFilters } from '../../hooks/filters'
import { Opera } from '../../types'
import { useDeleteOpera, useOpere } from '../../hooks/opere'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  ordering: params.get('ordering') ?? '',
  search: params.get('search') ?? '',
})

export default function OpereList() {
  const { filters, uiFilters, setFilters, setFiltersDebounced } =
    useQsFilters(initFilters)
  const { data } = useOpere(filters)
  const deleteOpera = useDeleteOpera()
  const [modalRm, modalRmActions] = useModalTrigger<Opera>()

  return (
    <Page padded>
      <Header>
        <div className="d-flex align-items-end">
          <h2 className="m-0">Opere</h2>
          <div className="counter ms-2 mb-1">
            <small>
              {data!.count}
              {' / '}
              {data!.total_count}
            </small>
          </div>
        </div>
        <Link className="btn btn-primary" to="/opere/new">
          <i className="bi bi-plus-circle me-2"></i> Aggiungi
        </Link>
      </Header>
      <div className="d-flex justify-content-between mt-3 mb-3 align-items-center">
        <input
          placeholder="Cerca"
          className="form-control w-auto"
          type="text"
          value={uiFilters.search}
          onChange={(e) =>
            setFiltersDebounced({ search: e.target.value, page: 1 })
          }
        />
      </div>
      <StickyTable className="flex-1">
        <Sorter
          value={uiFilters.ordering}
          onSortChange={(ordering) =>
            setFilters({
              ordering,
              page: 1,
            })
          }
        >
          <thead>
            <tr>
              <th>#</th>
              <th>
                <div className="d-flex align-items-center">
                  Titolo
                  <SortControl field={'titolo'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Autrice
                  <SortControl field={'autrice__nome'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Anno
                  <SortControl field={'anno_pubblicazione'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Stato pubblicazione
                  <SortControl field={'stato_pubblicazione'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                 Immagine
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
        </Sorter>
        <tbody>
          {data!.results.map((opera) => (
            <tr key={opera.id}>
              <td>{opera.id}</td>
              <td>
                <Link to={`/opere/${opera.id}`}>{opera.titolo}</Link>
              </td>
              <td>
                {opera.autrice && (
                  <Link to={`/autrici/${opera.autrice}`}>
                    {opera.autrice_data?.nome} {opera.autrice_data?.cognome}
                  </Link>
                )}
              </td>
              <td>{opera.anno_pubblicazione}</td>
              <td>{opera.stato_pubblicazione}</td>
              <td>
                {opera.immagine && (
                  <img
                    height={50}
                    src={opera.immagine}
                    alt={opera.titolo}
                  />
                )}

              </td>
              <td>
                <i
                  onClick={() => modalRmActions.open(opera)}
                  className="bi cursor-pointer text-danger bi-trash3-fill"
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare l'opera {modalRm.value.titolo} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteOpera.isLoading}
            onClick={async () => {
              await deleteOpera.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
      <div className="position-relative">
        <Paginator
          count={data!.count}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </Page>
  )
}
