import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { BaseVideosaggio, PaginatedDJResponse, Videosaggio } from '../types'
import { QueryOnlyOptions } from './utils'

export function useVideosaggi(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Videosaggio>>(
    ['videosaggi', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/videosaggi/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useVideosaggio(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<Videosaggio>(
    ['videosaggio', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/videosaggi/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteVideosaggio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/videosaggi/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['videosaggi'])
        client.invalidateQueries(['videosaggio'])
      },
    }
  )
}

export function useCreateVideosaggio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (video: BaseVideosaggio) =>
        axios
          .post(`/api/admin/videosaggi/`,video, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Videosaggio)
    ),
    {
      onSuccess(savedVideoSaggio) {
        client.invalidateQueries(['videosaggi'])
        client.setQueryData(['tevideosaggio', savedVideoSaggio.id], savedVideoSaggio)
      },
    }
  )
}

export function useUpdateVideosaggio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (video: Videosaggio) =>
        axios
          .put(
            `/api/admin/videosaggi/${video.id}/`,
            video,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Videosaggio)
    ),
    {
      onSuccess(savedVideoSaggio) {
        client.invalidateQueries(['videosaggi'])
        client.setQueryData(['videosaggio', savedVideoSaggio.id], savedVideoSaggio)
      },
    }
  )
}
