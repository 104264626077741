import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { BaseCategoriaOpera, CategoriaOpera } from '../types'
import { QueryOnlyOptions } from './utils'

export function useCategorieOpere(params: Record<string, any> = {}) {
  return useQuery<CategoriaOpera[]>(
    ['categorie-opere', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/categorie-opere/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useCategoriaOpera(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<CategoriaOpera>(
    ['categoria-opera', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/categorie-opere/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteCategoriaOpera() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/categorie-opere/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['categorie-opere'])
        client.invalidateQueries(['categoria-opera'])
      },
    }
  )
}

export function useCreateCategoriaOpera() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (categoria: BaseCategoriaOpera) =>
        axios
          .post(`/api/admin/categorie-opere/`, categoria, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as CategoriaOpera)
    ),
    {
      onSuccess(savedCategoria) {
        client.invalidateQueries(['categorie-opere'])
        client.setQueryData(
          ['categoria-opera', savedCategoria.id],
          savedCategoria
        )
      },
    }
  )
}

export function useUpdateCategoriaOpera() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (categoria: CategoriaOpera) =>
        axios
          .put(`/api/admin/categorie-opere/${categoria.id}/`, categoria, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as CategoriaOpera)
    ),
    {
      onSuccess(savedCategoria) {
        client.invalidateQueries(['categorie-opere'])
        client.setQueryData(
          ['categoria-opera', savedCategoria.id],
          savedCategoria
        )
      },
    }
  )
}

export function useUnificaCategorieOpera() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({
          categoria_to_keep,
          categorie_to_delete,
        }: {
          categoria_to_keep: number
          categorie_to_delete: number[]
        }) =>
          axios
            .post(
              `/api/admin/categorie-opere/unifica/`,
              { categoria_to_keep, categorie_to_delete },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as CategoriaOpera[])
    ),
    {
      onSuccess() {
        client.invalidateQueries(['categorie-opere'])
      },
    }
  )
}
