import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import GruppiList from './GruppiList'
import GruppoCreate from './GruppoCreate'
import GruppoEdit from './GruppoEdit'

export default function Users() {
  return (
    <Routes>
      <Route index element={<GruppiList />} />
      <Route path="new" element={<GruppoCreate />} />
      <Route path=":id" element={<GruppoEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
