import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { BaseCategoriaOpera, CategoriaOpera } from '../types'
import { InputField, transformErrorForForm } from './form'

const initialValues: BaseCategoriaOpera = {
  nome: '',
  ordine: 0,
}

const CategoriaOperaSchema = Yup.object().shape({
  nome: Yup.string().label('Nome'),
  ordine: Yup.number().label('Ordine'),
})

interface Props {
  categoria?: CategoriaOpera
  save: (categoria: CategoriaOpera) => Promise<unknown>
}

export default function CategoriaOperaForm({ save, categoria }: Props) {
  return (
    <Formik
      validationSchema={CategoriaOperaSchema}
      onSubmit={(gruppo, { setErrors }) =>
        save({
          ...gruppo,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(categoria ?? initialValues) as CategoriaOpera}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-6">
              <Field required label="Nome" name="nome" component={InputField} />
            </div>
            <div className="col-md-6">
              <Field
                required
                label="Ordine"
                name="ordine"
                component={InputField}
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
