import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { BaseClasse, Classe, PaginatedDJResponse, ReactSelectOption } from '../types'
import { QueryOnlyOptions } from './utils'

export function useClassiNoPaginate(params: Record<string, any> = {}) {
  return useQuery<Classe[]>(
    ['classi', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/classi/no-pag/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useClassiPadri(params: Record<string, any> = {}) {
  return useQuery<Classe[]>(
    ['classi-padre', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/classi/padri/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useClassi(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Classe>>(
    ['classi', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/classi/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useClasse(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<Classe>(
    ['classe', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/classi/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteClasse() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/classi/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['classi'])
        client.invalidateQueries(['classe'])
      },
    }
  )
}

export function useCreateClasse() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (classe: BaseClasse) =>
        axios
          .post(`/api/admin/classi/`, classe, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Classe)
    ),
    {
      onSuccess(savedClasse) {
        client.invalidateQueries(['classi'])
        client.setQueryData(['classe', savedClasse.id], savedClasse)
      },
    }
  )
}

export function useUpdateClasse() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (classe: Classe) =>
        axios
          .put(`/api/admin/classi/${classe.id}/`, classe, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Classe)
    ),
    {
      onSuccess(savedClasse) {
        client.invalidateQueries(['classi'])
        client.setQueryData(['classe', savedClasse.id], savedClasse)
      },
    }
  )
}

export function useUnificaClassi() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({
          classe_to_keep,
          classi_to_delete,
        }: {
          classe_to_keep: number
          classi_to_delete: number[]
        }) =>
          axios
            .post(
              `/api/admin/classi/unifica-classi/`,
              { classe_to_keep, classi_to_delete },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as Classe[])
    ),
    {
      onSuccess() {
        client.invalidateQueries(['classi'])
        client.invalidateQueries(['classi-autocomplete'])
      },
    }
  )
}

export function useClassiAutocomplete(params: Record<string, any> = {}) {

  return useQuery<ReactSelectOption[]>(
    ['classi-autocomplete', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/classi/autocomplete/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}
