import { TranslatedField } from './types'

export const LANGS = ['it', 'en'] as (keyof TranslatedField)[]

export const LANGS_WITHOUT_DEFAULT = ['en'] as (keyof TranslatedField)[]

export const MAIN_LANG = 'it'

export const PAGE_SIZE = 50

export const EMPTY_LANG_FIELD = LANGS.reduce((obj, lang) => {
  obj[lang] = ''
  return obj
}, {} as TranslatedField)


