import { Link, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Page from '../../components/Page'
import PersonaForm from '../../components/PersonaForm'
import { useCreatePersona } from '../../hooks/persone'

export default function PersonaCreate() {
  const createPersona = useCreatePersona()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/persone">Persone</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <PersonaForm
          save={async (persona) => {
            const savedPersona = await createPersona.mutateAsync(persona)
            navigate(`/persone/${savedPersona.id}`)
          }}
        />
      </div>
    </Page>
  )
}
