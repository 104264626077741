import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import BibliografieCreate from './BibliografieCreate'
import BibliografieList from './BibliografieList'
import BibliografieeEdit from './BibliografieEdit'

export default function Bibliografie() {
  return (
    <Routes>
      <Route index element={<BibliografieList />} />
      <Route path="new" element={<BibliografieCreate />} />
      <Route path=":id" element={<BibliografieeEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
