import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { BaseTema, Tema } from '../types'
import {
  InputField,
  RichTextField,
  TextareaField,
  transformErrorForForm,
} from './form'

const initialValues: BaseTema = {
  nome: '',
  descrizione: '',
}

const TemaSchema = Yup.object().shape({
  nome: Yup.string().label('Nome'),
})

interface Props {
  tema?: Tema
  save: (tema: Tema) => Promise<unknown>
}

export default function TemaForm({ save, tema }: Props) {
  return (
    <Formik
      validationSchema={TemaSchema}
      onSubmit={(tema, { setErrors }) =>
        save({
          ...tema,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(tema ?? initialValues) as Tema}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-6">
              <Field required label="Nome" name="nome" component={InputField} />
            </div>
            <div className="col-md-12">
              <Field
                label="Descrizione"
                name="descrizione"
                rows={4}
                component={RichTextField}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end mt-2">
            <button
              type="submit"
              disabled={isSubmitting || !isValid}
              className="btn btn-success"
            >
              <i className="text-white me-1 bi bi-save-fill"></i> Salva
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}
