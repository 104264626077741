import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import PassiList from './PassiList'
import PassoCreate from './PassoCreate'
import PassoEdit from './PassoEdit'

export default function Passi() {
  return (
    <Routes>
      <Route index element={<PassiList />} />
      <Route path="new" element={<PassoCreate />} />
      <Route path=":id" element={<PassoEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
