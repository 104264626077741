import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { BaseClasse, Classe, Tema } from '../types'
import {
  InputField,
  ReactSelectField,
  RichTextField,
  TextareaField,
  transformErrorForForm,
} from './form'
import { useTemiNoPaginate } from '../hooks/temi'

const initialValues: BaseClasse = {
  nome: '',
  descrizione: '',
  tema: null,
}

const OperaSchema = Yup.object().shape({
  nome: Yup.string().label('Nome').required(),
})

interface Props {
  classe?: Classe
  save: (classe: Classe) => Promise<unknown>
}

export default function ClasseForm({ save, classe }: Props) {
  const { data: temi } = useTemiNoPaginate()
  

  const temiOptions = temi?.map((c: Tema) => ({
    label: c.nome,
    value: c.id,
  }))

  return (
    <Formik
      validationSchema={OperaSchema}
      onSubmit={(classe, { setErrors }) => {
        save({
          ...classe,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }}
      validateOnMount
      initialValues={(classe ?? initialValues) as Classe}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12">
              <Field required label="Nome" name="nome" component={InputField} />
            </div>
            <div className="col-md-12">
              <Field
                label="Descrizione"
                name="descrizione"
                rows={4}
                component={RichTextField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Tema"
                name="tema"
                options={temiOptions}
                component={ReactSelectField}
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
