import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import TestiList from './TestiList'
import TestoCreate from './TestoCreate'
import TestoEdit from './TestoEdit'

export default function Testi() {
  return (
    <Routes>
      <Route index element={<TestiList />} />
      <Route path="new" element={<TestoCreate />} />
      <Route path=":id" element={<TestoEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
