import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { BaseCategoriaOpera, BaseTesto, CategoriaOpera, Testo } from '../types'
import { InputField, RichTextField, transformErrorForForm } from './form'

const initialValues: BaseTesto = {
  slug: '',
  descrizione: '',
  titolo: '',
}

const TestoSchema = Yup.object().shape({
  slug: Yup.string().label('Slug'),
  descrizione: Yup.string().label('Descrizione'),
  titolo: Yup.string().label('Titolo'),
})

interface Props {
  testo?: Testo
  save: (testo: Testo) => Promise<unknown>
}

export default function TestoForm({ save, testo }: Props) {
  return (
    <Formik
      validationSchema={TestoSchema}
      onSubmit={(testo, { setErrors }) =>
        save({
          ...testo,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(testo ?? initialValues) as Testo}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12">
              <Field required label="Slug" name="slug" component={InputField} />
            </div>
            <div className="col-md-12">
              <Field
                required
                label="Titolo"
                name="titolo"
                component={InputField}
              />
            </div>
            <div className="col-md-12">
              <Field
                required
                label="Descrizione"
                name="descrizione"
                component={RichTextField}
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
