import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { BasePasso, PaginatedDJResponse, PassiClassificatiCount, Passo } from '../types'
import { QueryOnlyOptions } from './utils'

export function usePassi(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Passo>>(
    ['passi', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/passi/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function usePassiClassificatiCount(params: Record<string, any> = {}) {
  return useQuery<PassiClassificatiCount>(
    ['passi-classificati-count', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/passi/totali-passi-classificati/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function usePasso(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<Passo>(
    ['passo', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/passi/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeletePasso() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/passi/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['passi'])
        client.invalidateQueries(['passo'])
      },
    }
  )
}

export function useCreatePasso() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (passo: BasePasso) =>
        axios
          .post(`/api/admin/passi/`, passo , {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Passo)
    ),
    {
      onSuccess(savedPasso) {
        client.invalidateQueries(['passi'])
        client.setQueryData(['passo', savedPasso.id], savedPasso)
      },
    }
  )
}

export function useUpdatePasso() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (passo: Passo) =>
        axios
          .put(
            `/api/admin/passi/${passo.id}/`,
            passo,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Passo)
    ),
    {
      onSuccess(savedPasso) {
        client.invalidateQueries(['passi'])
        client.setQueryData(['passo', savedPasso.id], savedPasso)
      },
    }
  )
}
