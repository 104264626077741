import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link } from 'react-router-dom'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap'
import Header from '../../components/Header'
import Page from '../../components/Page'
import Sorter, { SortControl } from '../../components/Sorter'
import StickyTable from '../../components/StickyTable'
import { useQsFilters } from '../../hooks/filters'
import { Nome } from '../../types'
import { useDeleteNome, useImportNomi, useNomi, useNomiAutocomplete, useUnificaNomi } from '../../hooks/nomi'
import { Field, Formik } from 'formik'
import { useOpereAutocomplete } from '../../hooks/opere'
import { FileField, MultiSelectField, ReactSelectField } from '../../components/form'
import Paginator from '../../components/Paginator'
import { useState } from 'react'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  ordering: params.get('ordering') ?? '',
  search: params.get('search') ?? '',
})

export default function NomiList() {
  const { filters, uiFilters, setFilters, setFiltersDebounced } =
    useQsFilters(initFilters)
  const { data, refetch } = useNomi(filters)
  const deleteCategoria = useDeleteNome()
  const [modalRm, modalRmActions] = useModalTrigger<Nome>()
  const [modalImport, modalImportActions] = useModalTrigger<any>()
  const [modalUnifica, modalUnificaActions] = useModalTrigger<any>()

  const unificaNomi = useUnificaNomi()

  const importNomi = useImportNomi()

  const opereParams = {
    page: 1,
    page_size: 1000,
  }
  const { data: opere } = useOpereAutocomplete(opereParams)

  const [isImporting, setIsImporting] = useState(false)

  const { data: nomi } = useNomiAutocomplete(opereParams)

  return (
    <Page padded>
      <Header>
        <div className="d-flex align-items-end">
          <h2 className="m-0">Nomi</h2>
          <div className="counter ms-2 mb-1">
            <small>
              {data!.count}
              {' / '}
              {data!.total_count}
            </small>
          </div>
        </div>
        <div className="d-flex">
          <Button
            color="primary"
            onClick={() => modalUnificaActions.open(true)}
            className="me-2"
            outline
          >
            <i className="bi bi-link-45deg me-1"></i> Unifica
          </Button>
          <div
            className="btn btn-primary pointer"
            onClick={modalImportActions.open}
          >
            <i className="bi bi-plus-circle me-2"></i> Importa
          </div>
        </div>
      </Header>
      <div className="d-flex justify-content-between mt-3 mb-3 align-items-center">
        <input
          placeholder="Cerca"
          className="form-control w-auto"
          type="text"
          value={uiFilters.search}
          onChange={(e) =>
            setFiltersDebounced({ search: e.target.value, page: 1 })
          }
        />
      </div>
      <StickyTable className="flex-1">
        <Sorter
          value={uiFilters.ordering}
          onSortChange={(ordering) =>
            setFilters({
              ordering,
              page: 1,
            })
          }
        >
          <thead>
            <tr>
              <th>#</th>
              <th>
                <div className="d-flex align-items-center">
                  Nome
                  <SortControl field={'nome'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Tipologia
                  <SortControl field={'tipologia'} />
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
        </Sorter>
        <tbody>
          {data!.results.map((nome) => (
            <tr key={nome.id}>
              <td>{nome.id}</td>
              <td>
                <Link to={`/nomi/${nome.id}`}>
                {nome.nome}
                </Link>
              </td>
              <td>{nome.tipologia}</td>
              <td>
                <i
                  onClick={() => modalRmActions.open(nome)}
                  className="bi cursor-pointer text-danger bi-trash3-fill"
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare il nome {modalRm.value.nome} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteCategoria.isLoading}
            onClick={async () => {
              await deleteCategoria.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
      <div className="position-relative"></div>
      <Modal
        centered
        isOpen={modalImport.isOpen}
        toggle={modalImportActions.toggle}
      >
        <Formik
          initialValues={{
            file: null,
            opera: null,
          }}
          onSubmit={async (values) => {
            if (!values.file) {
              return
            }
            if (!values.opera) {
              return
            }
            setIsImporting(true)
            await importNomi
              .mutateAsync({
                file: values.file,
                opera: values.opera,
              })
              .then(() => {
                setIsImporting(false)
                refetch()
              })
            modalImportActions.close()
          }}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ModalHeader toggle={modalImportActions.toggle}>
                Importa
              </ModalHeader>
              <ModalBody>
                <div className="pt-3 pb-3">
                  <Field
                    required
                    label="Opera"
                    name="opera"
                    placeholder="Seleziona un'opera"
                    options={opere}
                    component={ReactSelectField}
                  />
                </div>
                <div className="pb-3">
                  <Field
                    required
                    label="File"
                    name="file"
                    accept=".xlsx"
                    component={FileField}
                  />
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-between">
                <Button
                  onClick={modalImportActions.toggle}
                  outline
                  color="primary"
                >
                  Annulla
                </Button>
                <Button color="primary" type="submit" disabled={isImporting || !values.file || !values.opera}>
                  {isImporting && <Spinner size="sm" className="me-2" />}
                  <i className="bi bi-upload me-1"></i> Importa
                </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
      <Modal
        centered
        isOpen={modalUnifica.isOpen}
        toggle={modalUnificaActions.toggle}
      >
        <ModalHeader toggle={modalUnificaActions.toggle}>Unifica</ModalHeader>
        <Formik
          initialValues={{
            nome_to_keep: null,
            nomi_to_delete: [],
          }}
          onSubmit={async (values) => {
            console.log(values)
            if (
              !values.nome_to_keep ||
              values.nomi_to_delete.length === 0
            )
              return
            await unificaNomi.mutateAsync({
              nome_to_keep: values.nome_to_keep,
              nomi_to_delete: values.nomi_to_delete,
            }).then(() => {
              modalUnificaActions.toggle()
            }
            )
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <div>
                  <Field
                    component={ReactSelectField}
                    name="nome_to_keep"
                    label="Nome da mantenere"
                    options={nomi}
                  />
                </div>
                <div>
                  <Field
                    component={MultiSelectField}
                    name="nomi_to_delete"
                    label="Nomi da eliminare"
                    options={nomi?.filter(
                      (c) => c.value !== values.nome_to_keep
                    )}
                    isMulti
                  />
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-between">
                <button
                  onClick={modalUnificaActions.toggle}
                  className="btn btn-outline-primary"
                >
                  Annulla
                </button>
                <button className="btn btn-success" type="submit">
                  <i className="bi bi-save-fill me-1"></i>
                  Salva
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
      <div className="position-relative">
        <Paginator
          count={data!.count}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </Page>
  )
}
