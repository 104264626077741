import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Nome, PaginatedDJResponse, ReactSelectOption } from '../types'
import { makeFormDataEncoder, QueryOnlyOptions } from './utils'

export function useNomi(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Nome>>(
    ['nomi', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/nomi/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useNomiAutocomplete(params: Record<string, any> = {}) {

  return useQuery<ReactSelectOption[]>(
    ['nomi', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/nomi/autocomplete/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useNome(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<Nome>(
    ['nome', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/nomi/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteNome() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/nomi/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['nomi'])
        client.invalidateQueries(['nome'])
      },
    }
  )
}

const makeOperaFormData = makeFormDataEncoder({
  fileFields: ['file'],
  excludeFields: [],
})

interface ImportNomi {
  file: File
  opera: number
}

export function useImportNomi() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (importer: ImportNomi) =>
        axios
          .post(`/api/admin/opera-nomi/import-nomi/`, makeOperaFormData(importer), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data)
    )
  )
}

export function useUnificaNomi() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({
          nome_to_keep,
          nomi_to_delete,
        }: {
          nome_to_keep: number
          nomi_to_delete: number[]
        }) =>
          axios
            .post(
              `/api/admin/nomi/unifica-nomi/`,
              { nome_to_keep, nomi_to_delete },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as Nome[])
    ),
    {
      onSuccess() {
        client.invalidateQueries(['nomi'])
      },
    }
  )
}

export function useUpdateNome() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (nome: Nome) =>
        axios
          .put(`/api/admin/nomi/${nome.id}/`, nome, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Nome)
    ),
    {
      onSuccess(savedNome) {
        client.invalidateQueries(['nomi'])
        client.setQueryData(['nome', savedNome.id], savedNome)
      },
    }
  )
}

