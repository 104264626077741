import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { BaseTema, PaginatedDJResponse, Tema } from '../types'
import { QueryOnlyOptions } from './utils'

export function useTemiNoPaginate(params: Record<string, any> = {}) {
    return useQuery<Tema[]>(
      ['temi', params],
      useAuthCallPromise(
        (token: string) =>
          ({ signal }) =>
            axios
              .get(
                `/api/admin/temi/no-pag/?${new URLSearchParams(params).toString()}`,
                {
                  signal,
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((r) => r.data)
      ),
      {
        keepPreviousData: true,
      }
    )
  }

export function useTemi(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Tema>>(
    ['temi', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/temi/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useTema(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<Tema>(
    ['tema', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/temi/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteTema() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/temi/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['temi'])
        client.invalidateQueries(['tema'])
      },
    }
  )
}

export function useCreateTema() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (tema: BaseTema) =>
        axios
          .post(`/api/admin/temi/`, tema, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Tema)
    ),
    {
      onSuccess(savedTema) {
        client.invalidateQueries(['temi'])
        client.setQueryData(['tema', savedTema.id], savedTema)
      },
    }
  )
}

export function useUpdateTema() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (tema: Tema) =>
        axios
          .put(`/api/admin/temi/${tema.id}/`, tema, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Tema)
    ),
    {
      onSuccess(savedTema) {
        client.invalidateQueries(['temi'])
        client.setQueryData(['tema', savedTema.id], savedTema)
      },
    }
  )
}
