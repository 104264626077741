import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import AutriceCreate from './AutriceCreate'
import AutriceEdit from './AutriceEdit'
import AutriciList from './AutriciList'

export default function Attrici() {
  return (
    <Routes>
      <Route index element={<AutriciList />} />
      <Route path="new" element={<AutriceCreate />} />
      <Route path=":id" element={<AutriceEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
