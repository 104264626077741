import { Field, Formik } from 'formik'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { InputField, transformErrorForForm } from '../components/form'
import { recoverPassword } from '../hooks/password'

const RecoverPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required().label('Email'),
})

const initialValues = {
  email: '',
}

export default function RecoverPassword() {
  const [resetSended, setResetSended] = useState(false)
  return (
    <div
      className="h-100 w-100"   
    >
      <div className="row w-100 h-100 align-items-center p-2">
        <div className="col-md-4 offset-md-4">
          <div className="card">
            <div className="card-header card-header-login bg-white">
              <h5>Recupera Password</h5>
            </div>
            <div className="card-body">
              {resetSended ? (
                <div className="alert alert-success mt-2">
                  Richiesta di recuper password inviata con successo.
                  <br />
                  Se la tua email è nei nostri sistemi riceverai un messaggio
                  con le istruzione per resettare la tua password.
                  <br />
                  Vai al <Link to="/login">Login</Link>.
                </div>
              ) : (
                <Formik
                  onSubmit={(data, { setErrors }) =>
                    recoverPassword(data.email).then(
                      () => {
                        setResetSended(true)
                      },
                      (error) => {
                        setErrors(transformErrorForForm(error))
                      }
                    )
                  }
                  validationSchema={RecoverPasswordSchema}
                  initialValues={initialValues}
                >
                  {({ handleSubmit, errors, isSubmitting, isValid }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="pt-3 pb-3">
                        <Field
                          label="Email"
                          type="email"
                          name="email"
                          component={InputField}
                        />
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <Link
                          className="btn btn-outline-primary"
                          to="/login"
                        >
                          Torna al Login
                        </Link>
                        <button
                          type="submit"
                          disabled={isSubmitting || !isValid}
                          className="btn btn-primary"
                        >
                          Recupera Password
                        </button>
                      </div>
                      {(errors as any).__noFieldsServerError && (
                        <div className="alert alert-danger mt-2">
                          Impossibile eseguire la procedura di recupero password,
                          riprova più tardi.
                        </div>
                      )}
                    </form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
