import { Link, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Page from '../../components/Page'
import { useCreateTesto } from '../../hooks/testi'
import TestoForm from '../../components/TestoForm'

export default function TestoCreate() {
  const createTesto = useCreateTesto()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/testi">Testi</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <TestoForm
          save={async (testo) => {
            const savedTesto = await createTesto.mutateAsync(testo)
            navigate(`/testi/${savedTesto.id}`)
          }}
        />
      </div>
    </Page>
  )
}
