import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import PersoneList from './PersoneList'
import PersonaCreate from './PersonaCreate'
import PersonaEdit from './PersonaEdit'

export default function Persone() {
  return (
    <Routes>
      <Route index element={<PersoneList />} />
      <Route path="new" element={<PersonaCreate />} />
      <Route path=":id" element={<PersonaEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
