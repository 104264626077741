import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { Appuntamento, BaseAppuntamento, BaseCategoriaOpera, CategoriaOpera } from '../types'
import { DateField, InputField, RichTextField, TextareaField, transformErrorForForm } from './form'

const initialValues: BaseAppuntamento = {
  titolo: '',
  data: '',
  ora_inizio: '',
  descrizione_breve: '',
  luogo: '',
  programma: '',
  ora_fine: '',
  sottotiolo: '',
}

const AppuntamentoSchema = Yup.object().shape({
  titolo: Yup.string().label('Titolo').required(),
  data: Yup.string().label('Data').required(),
})

interface Props {
  appuntamento?: Appuntamento
  save: (appuntamento: Appuntamento) => Promise<unknown>
}

export default function AppuntamentoForm({ save, appuntamento }: Props) {
  return (
    <Formik
      validationSchema={AppuntamentoSchema}
      onSubmit={(appuntamento, { setErrors }) =>
        save({
          ...appuntamento,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(appuntamento ?? initialValues) as Appuntamento}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12">
              <Field required label="Titolo" name="titolo" component={InputField} />
            </div>
            <div className="col-md-12">
                <Field label="Sottotitolo" name="sottotitolo" component={InputField} />
            </div>
            <div className="col-md-4">
              <Field
                required
                label="Data"
                name="data"
                component={DateField}
              />
            </div>
            <div className="col-md-4">
              <Field
                required
                label="Ora inizio"
                name="ora_inizio"
                component={InputField}
              />
            </div>
            <div className="col-md-4">
              <Field
                required
                label="Ora fine"
                name="ora_fine"
                component={InputField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Descrizione breve"
                name="descrizione_breve"
                component={TextareaField}
                rows={3}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Luogo"
                name="luogo"
                component={InputField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label="Programma"
                name="programma"
                component={RichTextField}
                rows={3}
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
