import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Classe, Passo } from '../types'
import { groupBy } from 'lodash'
import { useEffect, useState } from 'react'

export default function ModalAssociazioneClasse({
  isOpen,
  toggle,
  classi,
  passo,
  onSave,
}: {
  isOpen: boolean
  toggle: () => void
  classi: Classe[] | undefined
  passo: Passo | undefined
  onSave: (classi: Classe[]) => void
}) {
  const classiGroupedByTema = groupBy(classi, 'tema_data.nome')
  const [classiSelezionate, setClassiSelezionate] = useState<
    Classe[] | null | undefined
  >([])

  const [temaSelezionato, setTemaSelezionato] = useState<string | undefined>(
    undefined
  )

  //   if passo is not undefined set classiSelezionate initial value to passo.classi
  useEffect(() => {
    if (passo) {
      setClassiSelezionate(passo.classi_data)
    }
  }, [passo])

  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Gestisci parole chiave</ModalHeader>
      <ModalBody>
        {/* make a list group with temi on the left and classi on the right */}
        <div className="row">
          <div className="col-md-4">
            <ul
              className="list-group"
              style={{
                maxHeight: 'calc(100vh - 200px)',
                overflowY: 'auto',
              }}
            >
              {Object.keys(classiGroupedByTema).map((tema) => (
                <li
                  key={tema}
                  onClick={() => setTemaSelezionato(tema)}
                  className={`list-group-item cursor-pointer ${
                    temaSelezionato === tema ? 'bg-primary text-white' : ''
                  }`}
                >
                  {tema}
                  {/* badge with count how many classi are selected */}
                  <span className="badge bg-secondary ms-2">
                    {
                      classiSelezionate?.filter(
                        (classe) => classe?.tema_data?.nome === tema
                      ).length
                    }
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-8">
            <ul
              className="list-group"
              style={{
                maxHeight: 'calc(100vh - 200px)',
                overflowY: 'auto',
              }}
            >
              {temaSelezionato &&
                classiGroupedByTema[temaSelezionato].map((classe) => (
                  <li
                    key={classe.id}
                    onClick={() => {
                      if (classiSelezionate?.some((c) => c.id === classe.id)) {
                        setClassiSelezionate(
                          classiSelezionate?.filter((c) => c.id !== classe.id)
                        )
                      } else {
                        setClassiSelezionate([...classiSelezionate!, classe])
                      }
                    }}
                    className={`list-group-item cursor-pointer ${
                      // check if in classiselezionate there is classe with same id
                      classiSelezionate?.some((c) => c.id === classe.id)
                        ? 'bg-primary text-white'
                        : ''
                    }`}
                  >
                    {classe.nome}
                    {/* add button with plus or minus icon in a circle
                    */}
                    <span
                      className={`badge float-end bg-secondary ms-2 ${
                        classiSelezionate?.some((c) => c.id === classe.id)
                          ? 'bg-danger'
                          : 'bg-success'
                      }`}
                    >
                      {classiSelezionate?.some((c) => c.id === classe.id) ? (
                        <i className="bi bi-dash" />
                      ) : (
                        <i className="bi bi-plus" />
                      )}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <button onClick={toggle} className="btn btn-outline-primary">
          Annulla
        </button>
        <button
          onClick={() => onSave(classiSelezionate!)}
          className="btn btn-primary"
        >
          Ok
        </button>
      </ModalFooter>
    </Modal>
  )
}
