import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link } from 'react-router-dom'
import { Badge, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Header from '../../components/Header'
import Page from '../../components/Page'
import Paginator from '../../components/Paginator'
import Sorter, { SortControl } from '../../components/Sorter'
import StickyTable from '../../components/StickyTable'
import { useQsFilters } from '../../hooks/filters'
import { Passo } from '../../types'
import {
  useDeletePasso,
  usePassi,
  usePassiClassificatiCount,
} from '../../hooks/passi'
import { useClassiNoPaginate } from '../../hooks/classi'
import ReactSelect from 'react-select'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  ordering: params.get('ordering') ?? '',
  search: params.get('search') ?? '',
  classe: params.get('classe') ?? '',
  is_classificato: params.get('is_classificato') ?? '',
  bozza: params.get('bozza') ?? '',
})

export default function PassiList() {
  const { filters, uiFilters, setFilters, setFiltersDebounced } =
    useQsFilters(initFilters)
  const { data } = usePassi(filters)
  const deletePasso = useDeletePasso()
  const [modalRm, modalRmActions] = useModalTrigger<Passo>()

  const { data: classi } = useClassiNoPaginate()

  const { data: totaliPassi } = usePassiClassificatiCount()

  const optionsClassi = classi?.map((classe) => ({
    value: classe.id,
    label: classe.nome + ' (' + classe.tema_data?.nome + ')',
  }))

  return (
    <Page padded>
      <Header>
        <div className="d-flex align-items-end">
          <h2 className="m-0">Passi</h2>
          <div className="counter ms-2 mb-1">
            <small>
              {data!.count}
              {' / '}
              {data!.total_count}
            </small>
          </div>
        </div>
        <div>
          <div>
            Passi classificati:{' '}
            <Badge color="success">{totaliPassi?.passi_classificati}</Badge>
          </div>
          <div>
            Passi non classificati:{' '}
            <Badge color="danger">{totaliPassi?.passi_non_classificati}</Badge>
          </div>
        </div>
        <Link className="btn btn-primary" to="/passi/new">
          <i className="bi bi-plus-circle me-2"></i> Aggiungi
        </Link>
      </Header>
      <div className="d-flex mt-3 mb-3 align-items-center">
        <input
          placeholder="Cerca"
          className="form-control w-auto"
          type="text"
          value={uiFilters.search}
          onChange={(e) =>
            setFiltersDebounced({ search: e.target.value, page: 1 })
          }
        />
        <select
          className="form-select w-auto ms-3"
          value={uiFilters.is_classificato}
          onChange={(e) =>
            setFilters({
              is_classificato: e.target.value,
              page: 1,
            })
          }
        >
          <option value="">Tutti i passi</option>
          <option value="true">Passi classificati</option>
          <option value="false">Passi non classificati</option>
        </select>
        <select
          className="form-select w-auto ms-3"
          value={uiFilters.bozza}
          onChange={(e) =>
            setFilters({
              bozza: e.target.value,
              page: 1,
            })
          }
        >
          <option value="">Tutti i passi</option>
          <option value="true">Passi in bozza</option>
          <option value="false">Passi non in bozza</option>
        </select>
        {classi && (
          <div
            className="w-100"
            style={{
              zIndex: 1000,
            }}
          >
            <ReactSelect
              className="w-100 ms-3"
              placeholder="Seleziona una classe"
              value={optionsClassi?.find(
                (classe) => classe.value === Number(uiFilters.classe)
              )}
              isClearable
              onChange={(classe) => {
                if (classe) {
                  setFilters({
                    classe: String(classe.value),
                    page: 1,
                  })
                } else {
                  setFilters({
                    classe: '',
                    page: 1,
                  })
                }
              }}
              options={optionsClassi}
            />
          </div>
        )}
      </div>
      <StickyTable className="flex-1">
        <Sorter
          value={uiFilters.ordering}
          onSortChange={(ordering) =>
            setFilters({
              ordering,
              page: 1,
            })
          }
        >
          <thead>
            <tr>
              <th>
                <div className="d-flex align-items-center">
                  Codice
                  <SortControl field={'id_passo'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Titolo
                  <SortControl field={'opera__titolo'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Autrice
                  <SortControl field={'opera__autrice__nome'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Indicazione pagina
                  <SortControl field={'indicazione_pagina'} />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Selezionato da
                  <SortControl field={'selezionato_da'} />
                </div>
              </th>
              
              <th>
                <div className="d-flex align-items-center">Classi</div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Bozza
                  <SortControl field={'bozza'} />
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
        </Sorter>
        <tbody>
          {data!.results.map((passo) => (
            <tr key={passo.id}>
              <td>{passo.id_passo}</td>
              <td>
                <Link to={`/passi/${passo.id}`}>
                  {passo.opera_data?.titolo}
                </Link>
              </td>
              <td>
                {passo.opera_data?.autrice_data && (
                  <Link to={`/autrici/${passo.opera_data?.autrice_data.id}`}>
                    {passo.opera_data?.autrice_data.nome} {passo.opera_data?.autrice_data.cognome}
                  </Link>
                )}
              </td>
              <td>{passo.indicazione_pagina}</td>
              <td>{passo.selezionato_da}</td>
              <td>
                {passo.classi_data?.map((classe) => (
                  <div key={classe.id}>{classe.nome}</div>
                ))}
              </td>
              <td>
                {passo.bozza ? 'Si' : 'No'}
              </td>
              <td>
                <i
                  onClick={() => modalRmActions.open(passo)}
                  className="bi cursor-pointer text-danger bi-trash3-fill"
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare il passo{' '}
              {modalRm.value.opera_data?.titolo}?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deletePasso.isLoading}
            onClick={async () => {
              await deletePasso.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
      <div className="position-relative">
        <Paginator
          count={data!.count}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </Page>
  )
}
