import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuthActions, useAuthState } from 'use-eazy-auth'
import styles from './Login.module.css'

export default function Login() {
  const { loginLoading, loginError } = useAuthState()
  const { login, clearLoginError } = useAuthActions()

  // Clear login error when Login component unmount
  useEffect(() => () => clearLoginError(), [clearLoginError])

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <div
      className="h-100 w-100"
      
    >
      <form
        className="row w-100 h-100 align-items-center p-2"
        onSubmit={(e) => {
          e.preventDefault()
          if (email !== '' && password !== '') {
            login({ email, password })
          }
        }}
      >
        <div className="col-md-4 offset-md-4">
          <div className="card">
            <div className={`card-header card-header-login bg-white`}>
              <h5 className={styles.Title}>DaMA - Amministrazione</h5>
            </div>
            <div className="card-body">
              <h3 className='pt-3'>Login</h3>
              <div className="mb-3 pt-3">
                <label>Email</label>
                <input
                  placeholder="Email"
                  name="email"
                  className="form-control"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    clearLoginError()
                    setEmail(e.target.value)
                  }}
                />
              </div>
              <div className="mb-3">
                <label>Password</label>
                <input
                  placeholder="Password"
                  name="password"
                  className="form-control"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    clearLoginError()
                    setPassword(e.target.value)
                  }}
                />
              </div>
              <p>
                <Link to="/password-recover">Password dimenticata?</Link>
              </p>
              <div className="d-flex justify-content-end mt-3">
                <button className="btn btn-primary" disabled={loginLoading}>
                  Login
                </button>
              </div>

              {loginError && (
                <div className="alert alert-danger mt-3">Credenziali errate.</div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
