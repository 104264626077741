import { Link, useNavigate } from 'react-router-dom'
import AutriceForm from '../../components/AutriceForm'
import Header from '../../components/Header'
import Page from '../../components/Page'
import { useCreateAutrice } from '../../hooks/autrici'

export default function AutriceCreate() {
  const createAttrice = useCreateAutrice()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/autrici">Autrici</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <AutriceForm
          save={async (attrice) => {
            const savedAttrice = await createAttrice.mutateAsync(attrice)
            navigate(`/autrici/${savedAttrice.id}`)
          }}
        />
      </div>
    </Page>
  )
}
