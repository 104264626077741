import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import CategorieOpereEdit from './CategorieOpereEdit'
import CategoriaOperaCreate from './CategoriaOperaCreate'
import CategorieOpereList from './CategorieOpereList'

export default function CategorieOpere() {
  return (
    <Routes>
      <Route index element={<CategorieOpereList />} />
      <Route path="new" element={<CategoriaOperaCreate />} />
      <Route path=":id" element={<CategorieOpereEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
