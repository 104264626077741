import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import ClassiList from './ClassiList'
import ClasseCreate from './ClasseCreate'
import ClasseEdit from './ClasseEdit'

export default function Classi() {
  return (
    <Routes>
      <Route index element={<ClassiList />} />
      <Route path="new" element={<ClasseCreate />} />
      <Route path=":id" element={<ClasseEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
