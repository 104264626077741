import { Link, useNavigate, useParams } from 'react-router-dom'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Bibliografia } from '../../types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Page from '../../components/Page'
import Header from '../../components/Header'
import { useBibliografia, useDeleteBibliografia, useUpdateBibliografia } from '../../hooks/bibliografie'
import BibliografiaForm from '../../components/BibliografiaForm'

export default function BibliografieeEdit() {
  const { id } = useParams()
  const { data: biblio } = useBibliografia(+id!)
  const deleteBibliografia = useDeleteBibliografia()
  const updateBiografia = useUpdateBibliografia()
  const [modalRm, modalRmActions] = useModalTrigger<Bibliografia>()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/bibliografie">Bibliografie</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {biblio?.id}
            </li>
          </ol>
        </nav>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-danger"
            onClick={() => modalRmActions.open(biblio)}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </div>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <BibliografiaForm
          bibliografia={biblio}
          save={(biblio) =>
            updateBiografia.mutateAsync(biblio).then(() =>
              toast.success('Bibliografia aggiornata')
            )
          }
        />
      </div>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare la bibliografia {modalRm.value.descrizione_plain} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn btn-danger"
            disabled={deleteBibliografia.isLoading}
            onClick={async () => {
              await deleteBibliografia.mutateAsync(modalRm.value!.id)
              navigate('/bibliografie')
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
    </Page>
  )
}
