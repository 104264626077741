import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { BaseBibliografia, BaseTesto, Bibliografia, PaginatedDJResponse, ReactSelectOption, Testo } from '../types'
import { QueryOnlyOptions } from './utils'

export function useBibliografie(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Bibliografia>>(
    ['bibliografie', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/bibliografie/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useBibliografieAutocomplete(params: Record<string, any> = {}) {

    return useQuery<ReactSelectOption[]>(
      ['bibliografie-select', params],
      useAuthCallPromise(
        (token: string) =>
          ({ signal }) =>
            axios
              .get(
                `/api/admin/bibliografie/autocomplete/?${new URLSearchParams(params).toString()}`,
                {
                  signal,
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((r) => r.data)
      ),
      {
        keepPreviousData: true,
      }
    )
  }

export function useBibliografia(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<Bibliografia>(
    ['bibliografia', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/bibliografie/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteBibliografia() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/bibliografie/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['bibliografie'])
        client.invalidateQueries(['bibliografia'])
      },
    }
  )
}

export function useCreateBibliografia() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (bibliografia: BaseBibliografia) =>
        axios
          .post(`/api/admin/bibliografie/`,bibliografia, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Bibliografia)
    ),
    {
      onSuccess(savedBiblio) {
        client.invalidateQueries(['bibliografie'])
        client.setQueryData(['bibliografia', savedBiblio.id], savedBiblio)
      },
    }
  )
}

export function useUpdateBibliografia() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (bibliografia: Bibliografia) =>
        axios
          .put(
            `/api/admin/bibliografie/${bibliografia.id}/`,
            bibliografia,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Bibliografia)
    ),
    {
      onSuccess(savedBiblio) {
        client.invalidateQueries(['bibliografie'])
        client.setQueryData(['bibliografia', savedBiblio.id], savedBiblio)
      },
    }
  )
}
